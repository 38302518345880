import React, { useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { Link, Button } from "react-router-dom";
import swal2 from 'sweetalert2'
import swal from "sweetalert"

const ValidBadges = () => {
  const [badges, setBadges] = useState([]);
  const [validBadges, setValidBadges] = useState([]);
  const [test, setTest] = useState([]);
  const [disableUpdate, setDisableUpdate] = useState(true);

  useEffect(() => {
    loadBadges();
    loadValidBadges();
    loadTest();
  }, []);

  const loadBadges = async () => {
    const result = await axios.get("https://localhost:8443/getFilteredCopsManifest");

    setBadges(result.data.badges);
  };

  const loadTest = async () => {
    const result = await axios.get(
      "https://smes-prod-iad.iad.proxy.amazon.com/api/enrichment-configs/certification"
    );
    setTest(result.data);
    console.log("SMES", result.data);
  };

  const loadValidBadges = async () => {
    const result = await axios.get(
      process.env.REACT_APP_AQUILINE_BACKEND + "getAllValidBadges"
    );
    setValidBadges(result.data);
  };

  function onClick() {
    console.log("TEST", validBadges);
    console.log("Status of Update button", disableUpdate)

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(validBadges),
    };
    console.log("Request", requestOptions);

    swal2.fire({
      title: 'Are you sure?',
      text: "This will add new talent configuration",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Add!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          process.env.REACT_APP_AQUILINE_BACKEND + "updateAllValidBadges",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            return data;
          });
        swal({ text: " Badges Successfully Updated!!", type: "success" }).then(
          (okay) => {
            if (okay) {
              window.location.href = process.env.REACT_APP_REDIRECT_URL + "ValidBadges";
            }
          }
        );
      }
    })

    fetch(
      process.env.REACT_APP_AQUILINE_BACKEND + "updateAllValidBadges",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data;
      });
  }

  return (
    <>

      <div className="container">
        <div className="py-4">
          <div className="name">
            <h3 className="headerInline">Valid Badges</h3>
            <div className="submitRequest submit headerInline headerMargin textSizeHeader" onClick={onClick}>
              <h7></h7>{" "}
              <button className="link-align enable-styling button-styling" disabled={disableUpdate}>
                Update List
            </button>
            </div>
          </div>
          <div><hr className="hrStylingBadges" /> </div>
          <div className=" main-wrapper">
            {badges.length &&
              badges.map((item, index) => {

                if (validBadges.includes(item)) {
                  return (
                    <>
                      <div className="sections textSize upperCaseFont wrapContent" key={index}>
                        <input
                          className="checkboxSpacing"
                          type="checkbox"
                          id={item}
                          onChange={(e) => {
                            let temp = [...validBadges];
                            let deletedIndex = temp.indexOf(e.target.value);
                            temp.splice(deletedIndex, 1);
                            setValidBadges([...temp]);
                            setDisableUpdate(false);
                          }}
                          value={item}
                          checked
                        ></input>
                        <label className="checkboxSpacing" for={item}>{item}</label>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <>
                      <div className="sections textSize upperCaseFont wrapContent" key={index}>
                        <input
                          className="checkboxSpacing"
                          type="checkbox"
                          id={item}
                          value={item}
                          onChange={(e) => {
                            setValidBadges([...validBadges, e.target.value]);
                            setDisableUpdate(false);
                          }}
                        ></input>
                        <label className="checkboxSpacing"
                        // for={item}
                        >{item}</label>
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ValidBadges);
