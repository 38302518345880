import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ListGroup, Button } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";
import swal from "sweetalert";
import UserId from '../Auth/UserId'
import swal2 from 'sweetalert2'

const TalentConfiguration = () => {
  const [talentConfigurations, setTalentConfigurations] = useState([]);

  useEffect(() => {
    loadTalentConfigurations();
  }, []);

  const loadTalentConfigurations = async () => {
    const result = await axios.get(
      process.env.REACT_APP_AQUILINE_BACKEND + "getActiveTalentConfigurationDashboardDetails"
    );
    setTalentConfigurations(result.data);
  };

  const [search, setSearch] = useState("");
  const [filteredConfigs, setFilteredConfigs] = useState([]);

  useEffect(() => {
    setFilteredConfigs(
      talentConfigurations.filter((config) =>
        config.talentConfigurationName
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    );
  }, [search, talentConfigurations]);

  const [reportDetails, setReportDetails] = useState([]);
  useEffect(() => {
    loadReportDetails();
  }, []);

  const loadReportDetails = async () => {
    const reportDetail = await axios.get(
      process.env.REACT_APP_AQUILINE_BACKEND + "getReportDetails");
    setReportDetails(reportDetail.data);
  };

  var reportData = []
  reportDetails.filter((configDetail) =>
    reportData.push(configDetail.configDetail).toString());
  console.log(reportData);


  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("");
  }

  function onClick(item) {
    console.log("ConfogName: " + item);
    const currentUser = UserId();
    const date = Date();
    let dateMDY = convertDate(date)
    let history = {
      modifiedBy: currentUser,
      modifiedOn: parseInt(dateMDY, 10)
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        configId: item,
        history: history
      }),
    };
    console.log("Request", requestOptions);

    swal2.fire({
      title: 'Are you sure?',
      text: "You want to disable the Talent Configuration: " + item + "!!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Disable!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(process.env.REACT_APP_AQUILINE_BACKEND + "disableTC", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            return data;
          });
        swal({
          text: item + " Disabled!!",
          icon: "success",
        }).then((okay) => {
          if (okay) {
            window.location.href = process.env.REACT_APP_REDIRECT_URL + "TalentConfigurations";
          }
        });
      }
    })
  }

  return (
    <div className="container">
      <div className="py-4">
        <h3>Talent Configurations</h3>
        <CSVLink className="textSizeSemiHeader" data={reportData} filename={"TCs_Detail"} > Download Report </CSVLink>
        <div className="col-sm-3 col-md-3 pull-right">
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          {/* <div className="search-box-container search-box pull-right"> */}
          <input
            name="search"
            type="text"
            id="search"
            className="search-box-container search-box pull-right"
            placeholder="Search here...          🔍"
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* </div> */}
        </div>

        <table className="table w3-hoverable">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Testers Onqueue</th>
              <th>Testers Offqueue</th>
              <th>Testers Onbreak</th>
              <th>Testers In Config</th>
              <th>Last Modified By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredConfigs.map((item, index) => (
              <tr align="center">
                <td>{index + 1}</td>
                <td align="left">{item.talentConfigurationName}</td>
                <td>{item.onQueueTestersCount}</td>
                <td>{item.offQueueTestersCount}</td>
                <td>{item.unavailableTestersCount}</td>
                <td>{item.numberOfTesters}</td>
                <td>{item.modifiedBy !== null ? item.modifiedBy : "-"}</td>
                <td align="left">
                  <Link
                    className="link-align view-styling"
                    to={`/ViewConfigs/${item.talentConfigurationName}`}
                  >
                    View
                  </Link>
                  <Link class="link-align edit-styling" to={``}>
                    Edit
                  </Link>
                  <Link class="link-align disable-styling" onClick={() => onClick(item.talentConfigurationName)}>
                    Disable
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TalentConfiguration;
