import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import swal from "sweetalert";
import swal2 from 'sweetalert2'

const TesterProfile = () => {
  const [testerProfiles, setTesterProfiles] = useState([]);
  const [testersAdded, setTestersAdded] = useState([]);

  const [search, setSearch] = useState("");

  useEffect(() => {
    loadTesterProfiles();
    loadTestersAdded();
  }, []);

  const loadTestersAdded = async () => {
    const result = await axios.get(
      process.env.REACT_APP_AQUILINE_BACKEND + "syncNasaTesters"
    );
    setTestersAdded(result.data);
    console.log("setTestersAdded");
    console.log(result.data);
  };

  const loadTesterProfiles = async () => {
    const result = await axios.get(
      process.env.REACT_APP_AQUILINE_BACKEND + "getTesterDashboardDetails"
    );
    setTesterProfiles(result.data);
    // console.log("TesterProfile");
    // console.log(result.data);
  };

  let filteredTps = testerProfiles.filter((profile) => {
    return profile.testerName.toLowerCase().includes(search.toLowerCase());
  });

  function hitEndpoint(url) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      //we're done making the request
      if (this.readyState === 4) {
        //status code was successful
        if (this.status === 200) {
          var testers = JSON.stringify(testersAdded);
          var splitTestersInfo = testers.split(',');
          var res = "" + splitTestersInfo[2];
          var result = res.split(':');
          var testersCount = result[1];
          console.log("Testers Added", testersCount);

          swal2.fire({
            title: 'Sync Successful',
            text: "{Number of new testers synced from NASA: " + testersCount,
            icon: "success",
          });
        } else {
          swal2.fire("There was some issue, please try again");
        }
      }
    };
    xhttp.open("GET", url);
    xhttp.send();
  }

  const submit = () => {
    swal2.fire({
      title: 'Are you sure?',
      text: "All the new testers will be synced from NASA",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Sync it!'
    }).then((result) => {
      if (result.isConfirmed) {
        hitEndpoint(process.env.REACT_APP_AQUILINE_BACKEND + "syncNasaTesters")
      }
    })
  };


  return (
    <div className="container">
      <div className="py-4">
        <div className="name headerInline ">
          <h3 className="headerInline " >Tester Profiles </h3>
          <div className="button headerInline headerMargin textSize">
            <button className="link-align headerMargin view-styling button-styling"
              onClick={submit}>
              Sync testers from NASA
                  </button>
          </div>
        </div>

        <div class=" textSizeHeader searchBarstyle">
          <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
          <input name="search" type="text" id="search" className="search-box-container search-box headerInline"
            placeholder="Search here...          🔍" onChange={(e) => setSearch(e.target.value)} />
        </div>
        <table className="table w3-hoverable">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Alias</th>
              <th>Manger</th>
              <th>Team</th>
              <th>Status</th>
              <th>OffQueue Activity</th>
              <th>Time In Status</th>
              <th> No. of Configs</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              filteredTps.map((tester, index) => {
                return (
                  <tr align="left">
                    <td>{index + 1}</td>
                    <td>{tester.testerName}</td>
                    <td>{tester.testerName}</td>
                    <td>{tester.manager}</td>
                    <td>{tester.team}</td>
                    <td>{tester.currentStatus}</td>
                    <td align="center">{tester.reason !== null ? tester.reason : "-"}</td>
                    <td align="center">
                      {tester.currentStatusTime !== null
                        ? tester.currentStatusTime
                        : "-"}
                    </td>
                    <td align="center">
                      {tester.totalConfigsAssigned !== null
                        ? tester.totalConfigsAssigned
                        : 0}
                    </td>
                    <td align="center">
                      <Link
                        className="link-align view-styling"
                        to={`/ViewTester/${tester.testerName}`}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TesterProfile;
