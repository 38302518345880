import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import UserId from '../Auth/UserId'
import swal2 from 'sweetalert2'

const DisabledConfigs = () => {
  const [disabledConfigs, setDisabledConfigs] = useState([]);

  useEffect(() => {
    loadDisabledConfigs();
  }, []);

  const loadDisabledConfigs = async () => {
    const result = await axios.get(
      process.env.REACT_APP_AQUILINE_BACKEND + "getDisabledTalentConfigurationDashboardDetails"
    );
    setDisabledConfigs(result.data);
  };

  const [search, setSearch] = useState("");
  const [filteredDisabledConfigs, setFilteredDisabledConfigs] = useState([]);

  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("");
  }

  function onClick(item) {
    console.log("ConfogName: " + item);
    const currentUser = UserId();
    const date = Date();
    let dateMDY = convertDate(date)
    let history = {
      modifiedBy: currentUser,
      modifiedOn: parseInt(dateMDY, 10)
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        configId: item,
        history: history
      }),
    };
    console.log("Request", requestOptions);

    swal2.fire({
      title: 'Are you sure?',
      text: "You want to enable the Talent Configuration: " + item + "!!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Enable!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(process.env.REACT_APP_AQUILINE_BACKEND + "enableTC", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            return data;
          });
        swal({
          text: item + " Enabled!!",
          icon: "success",
        }).then((okay) => {
          if (okay) {
            window.location.href = process.env.REACT_APP_REDIRECT_URL + "disabled-configurations";
          }
        });
      }
    })

    // fetch(process.env.REACT_APP_AQUILINE_BACKEND + "enableTC", requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //     return data;
    //   });
    // swal({
    //   text: "Configuration Enabled",
    //   icon: "success",
    // }).then((okay) => {
    //   if (okay) {
    //     window.location.href = process.env.REACT_APP_REDIRECT_URL + "disabled-configurations";
    //   }
    // });
  }

  useEffect(() => {
    setFilteredDisabledConfigs(
      disabledConfigs.filter((config) =>
        config.talentConfigurationName
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    );
  }, [search, disabledConfigs]);

  return (
    <div className="container">
      <div className="py-4">
        <h3>Disabled Configurations</h3>
        <div class="col-sm-3 col-md-3 pull-right">
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          {/* <div className="search-box-container search-box"> */}
          <input
            name="search"
            type="text"
            id="search"
            className="search-box-container search-box pull-right"
            placeholder="Search here...          🔍"
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* </div> */}
        </div>

        <table className="table w3-hoverable">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Tester Onqueue</th>
              <th>Tester Offqueue</th>
              <th>Tester Onbreak</th>
              <th>Testers In Config</th>
              <th>Last Modified By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredDisabledConfigs.map((item, index) => (
              <tr align="center">
                <td>{index + 1}</td>
                <td align="left">{item.talentConfigurationName}</td>
                <td>{item.onQueueTestersCount}</td>
                <td>{item.offQueueTestersCount}</td>
                <td>{item.unavailableTestersCount}</td>
                <td>{item.numberOfTesters}</td>
                <td>{item.modifiedBy !== null ? item.modifiedBy : "-"}</td>
                <td align="left">
                  <button className="link-align enable-styling button-styling" onClick={() => onClick(item.talentConfigurationName)}>
                    Enable </button>
                  <button className="link-align disable-styling button-styling " to={``}>
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default DisabledConfigs;
