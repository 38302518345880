import React, { Component } from "react";
import { Modal, Button, ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import Select from "react-select";
import swal from "sweetalert";

export class EditPriorityPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitDisabled: true,
    };
  }

  onHide = () => {
    this.setState({}, () => {
      this.props.onHide()
    });
  };


  render() {
    console.log("TesterName " + this.props.data);
    const obj = this.props.data
    const list = obj && Object.values(obj).join();
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" class="textSizeHeader addNewStyling">
            Edit Priority
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div >
            {this.props.data &&
              Object.entries(this.props.data).map(([key, value]) => {
                return (
                  <form>
                    <div >
                      <div className=" col">
                        <InputGroup>
                          <label className="textSize addNewStyling">{key}</label>
                          <Input
                            className="textSize addNewStyling"
                            placeholder="1-99"
                            min={1}
                            max={99}
                            type="number"
                            step="1"
                            onChange={this.props.onPriorityChange}
                            value={value}
                            name={key}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </form>
                );
              })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Duplicate values are not allowed!!!</Tooltip>}>
            <span className="d-inline-block">
              <Button
                className="link-align enable-styling button-styling textSizeHeader"
                type="submit"
                variant="primary"
                onClick={this.props.onSubmit}
                disabled={this.props.submitDisabled}
              // style={{ pointerEvents: 'none' }}
              >
                Save
    </Button>
            </span>
          </OverlayTrigger>
          {/* <Button
            className="link-align enable-styling button-styling textSizeHeader"
            type="submit"
            variant="primary"
            disabled={this.props.submitDisabled}
            onClick={this.props.onSubmit}
          >
            Save
          </Button> */}
          <Button variant="danger" className="link-align disable-styling button-styling  textSizeHeader" onClick={this.onHide}>
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
