import React from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import swal from "sweetalert";
import Moment from 'moment';
import UserId from '../Auth/UserId'
import { AddTesterPopUp } from "./AddTesterPopUp";
import { MatchingLogicPopUp } from "./MatchingLogicPopUp";
import swal2 from 'sweetalert2'


const config = [
  "supportedDevices",
  "activityType",
  "locales",
  "monitoringTriggerTypes",
  "badges",
  "skillTier",
  "skillTypes",
  "workflowTypes",
];

const defaultMapping = [
  {
    cardName: "badges",
    options: [{ radioName: "Subset" }, { radioName: "Exact" }],
  },
  {
    cardName: "skillTypes",
    options: [{ radioName: "Subset" }, { radioName: "Exact" }],
  },
  {
    cardName: "devices",
    options: [{ radioName: "Subset" }, { radioName: "Exact" }],
  },
]

class ViewConfigs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      talentConfig: {},
      testerList: [],
      testerNotInConfig: [],
      selectedOptionId: 0,
      addModalShow: false,
      addModalShowMatch: false,
      addModalShowPopUp: false,
      configName: "",
      status: true,
      configurationMapping: {},
      createdBy: "",
      modifiedBy: "",
      description: "",
      data1: [
        {
          cardName: "badges",
          options: [{ radioName: "Subset" }, { radioName: "Exact" }],
        },
        {
          cardName: "skillTypes",
          options: [{ radioName: "Subset" }, { radioName: "Exact" }],
        },
        {
          cardName: "devices",
          options: [{ radioName: "Subset" }, { radioName: "Exact" }],
        },
      ],
      submitDisabled: true,
      disableSavebtn: true,
    };
  }

  //Trying using props data
  onSubmit = (e) => {
    e.preventDefault();
    // this.onMappingChange(this.state.data1);
    console.log("OnSubmit Modified Data", this.state.data1);
    swal({
      title: "Are you sure?",
      text: "This will make changes in Matching Configuration",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willClose) => {
      if (willClose) {
        swal("Successfully updated mapping configuration. To make it reflected in the configuration please click on top right Save button!", {
          icon: "success",
        });
        this.setState({ addModalShowMatch: false });
      } else {
        swal("Discard save!");
        this.setState({ addModalShowMatch: false, disableSavebtn: true, data1: [...this.state.prevData] });
      }
    });
  };

  onInputChange = ({ target }) => {
    const { data1, submitDisabled, disableSavebtn, data } = this.state;
    const mappingprev = data.configurationMapping
    const mappingprevNew = {}
    console.log("API Data for Mapping Logic", mappingprev)
    console.log("Flag value:", this.state.disableSavebtn)
    mappingprev && Object.entries(mappingprev).forEach(([key, value]) => {
      if (value === true) {
        mappingprevNew[key] = 1
      }
      else {
        mappingprevNew[key] = 0
      }
    });
    console.log(mappingprev)
    const nexState = data1.map((card) => {
      if (card.cardName !== target.name) return card;
      return {
        ...card,
        options: card.options.map((opt) => {
          const checked = opt.radioName === target.value;
          return {
            ...opt,
            selected: checked,
          };
        }),
      };
    });
    console.log("nextState", nexState)
    console.log("data1", data1)

    let flag;
    const mappingUpdate = this.getFormatedMapping(nexState)
    if (JSON.stringify(mappingprevNew) === JSON.stringify(mappingUpdate)) {
      flag = true
    }
    else {
      flag = false
    }
    console.log("Flag value after:", this.state.disableSavebtn, flag)
    this.setState({
      data1: nexState,
      submitDisabled: flag,
      disableSavebtn: flag
    });
    console.log("Data1 in ONChange", this.state.data1);
  };

  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_AQUILINE_BACKEND + "getMasterTalentConfiguration"
      )
      .then((res) => {
        this.setState({
          talentConfig: res.data,
        });
        this.getTalentConfigByConfigId();
        this.getTesterListByConfigId();
        this.getTesterNotInList();
      });
  }

  getTalentConfigByConfigId() {
    const talentConfigurationName = this.props.match.params.talentConfigurationName;
    axios
      .get(
        process.env.REACT_APP_AQUILINE_BACKEND + "getTC/" + talentConfigurationName
      )
      .then((res) => {
        if (!res.data.configurationMapping) {
          this.setState({
            data1: [...defaultMapping],
            data: res.data,
            description: res.data.description,
            prevData: [...defaultMapping]
          })
        } else {
          const newData = [...this.state.data1];
          newData && newData.forEach((item) => {
            const cardName = item.cardName;
            item.options.map(subItem => {
              if (!res.data.configurationMapping[cardName] &&
                subItem.radioName === "Subset"
              ) {
                subItem.selected = true;
              } else if (
                res.data.configurationMapping[cardName] &&
                subItem.radioName === "Exact"
              ) {
                subItem.selected = true;
              }
            })
          })
          this.setState({
            data1: newData,
            data: res.data,
            prevData: newData,
            description: res.data.description
            // createdBy: JSON.stringify(Object.entries(this.state.data.history)[0][1]),
            // modifiedBy: Object.entries(this.state.data.history)[2][1]
          }, () => console.log("Data inside API", Object.entries(this.state.data.history)[0][1]), console.log("Desc inside API", this.state.description));

        }
      });
  }

  getTesterListByConfigId() {
    const talentConfigurationName = this.props.match.params
      .talentConfigurationName;
    axios
      .get(
        process.env.REACT_APP_AQUILINE_BACKEND + "getTestersStatusInTC/" + talentConfigurationName
      )
      .then((res) => {
        this.setState({
          testerList: res.data,
        });

        // console.log("Tester List" + Object.keys(data.history)[0][1]);
      });
  }

  getTesterNotInList() {
    const talentConfigurationName = this.props.match.params
      .talentConfigurationName;
    axios
      .get(
        process.env.REACT_APP_AQUILINE_BACKEND + "getTestersNotInTalentConfiguration/" + talentConfigurationName
      )
      .then((res) => {
        this.setState({
          testerNotInConfig: res.data,
        });
        console.log(res.data);
      });
  }

  verifyCheckbox(parent, child) {
    const { configuration } = this.state.data || {};
    if (!configuration || !configuration[parent]) {
      return false;
    }
    return configuration[parent].find((item) => item === child);
  }

  handleClick() {
    this.setState({ clicked: !this.state.clicked });
  }

  handleChange = (event) => {
    let flag = event.target.checked ? false : true;
    this.setState(
      {
        status: event.target.checked,
        submitDisabled: !flag
      }
      // () => console.log(this.state.status)
    );
  };

  sumbitRequest(tester, tc) {
    const talentConfigurationName = this.props.match.params
      .talentConfigurationName;
    console.log(talentConfigurationName);
    console.log("TalentConfig" + tc);
    console.log("Tester Name", tester);
    var jsonObj = {};
    jsonObj[tc] = 99;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: tester,
        entitledConfigurations: jsonObj,
      }),
    };
    console.log(requestOptions);
    console.log(jsonObj);
    swal2.fire({
      title: 'Are you sure?',
      text: tester + " will be removed from " + tc + "!!!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          process.env.REACT_APP_AQUILINE_BACKEND + "removeTesterFromTC",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            return data;
          })
          .then((data) => this.setState({ apiResponse: data }));
        swal({ text: tester + " Successfully Removed!!", type: "success" }).then(
          (okay) => {
            if (okay) {
              window.location.href = process.env.REACT_APP_REDIRECT_URL + "TalentConfigurations";
            }
          }
        );
      }
    })
  }

  handleConfig = (isChecked, parent, child) => {
    const { configuration } = this.state.data;
    console.log("Before ", configuration);
    let temp = configuration;
    let flag;
    console.log("Assigned Config", temp);
    if (isChecked) {
      if (!temp[parent]) temp[parent] = [];
      temp[parent].push(child);
      flag = false;
      console.log("array data for isCheck true 1", temp)
      this.setState({ configuration: temp, submitDisabled: flag });
    } else if (isChecked === false) {
      if (!temp[parent]) temp[parent] = [];
      var popped = temp[parent].splice(temp[parent].indexOf(child), 1);
      console.log("array data2 for false 1", popped)
      if (popped == undefined) {
        temp[parent] = null;
        console.log("array data for false 2", temp)
      }
      flag = false
      this.setState({ configuration: temp, submitDisabled: flag });
    }
    this.setState({ configuration: temp });
    console.log(configuration);
    console.log("array data final", temp)
  };

  //Based on radioName and selected value, forming
  getFormatedMapping = (data) => {
    let arrayObj = [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < 2; j++) {
        if (data[i].options[j].selected) {
          if (data[i].options[j].radioName === "Exact") {
            arrayObj.push({
              [data[i].cardName]: 1,
            });
          } else {
            arrayObj.push({
              [data[i].cardName]: 0,
            });
          }
        }
      }
    }

    var object = Object.assign({}, ...arrayObj);

    console.log(object);
    return object;
  };

  convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("");
  }

  onSubmitRequest = () => {
    const currentUser = UserId();
    const date = Date();
    let dateMDY = this.convertDate(date)
    const mapping = this.getFormatedMapping(this.state.data1);
    // this.setState({ data1: mapping });
    console.log("Updated Config inside Save", this.state.data.configuration);
    console.log("Updated Status", this.state.status);
    console.log("Updated Mapping", this.state.data1);
    console.log("Description before commiting Save", this.state.description)

    let noOfTester = JSON.stringify(this.state.testerList.length)
    let configName = JSON.stringify(this.state.data.configId)
    console.log("No of Testers Mapped", noOfTester)
    let history = {
      modifiedBy: currentUser,
      modifiedOn: parseInt(dateMDY, 10)
    }
    let requestBody = {
      configId: this.state.data.configId,
      version: this.state.data.version,
      history: this.state.data.history,
      description: this.state.description,
      isEnabled: this.state.status,
      configuration: this.state.data.configuration,
      configurationMapping: mapping,
      history: history
    };
    console.log(requestBody);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };
    swal2.fire({
      title: 'Are you sure?',
      text: "Existing configuration will be updated with the changes !!" + " No Of Testers Mapped with " + configName + ":" + noOfTester,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(process.env.REACT_APP_AQUILINE_BACKEND + "updateTC", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            this.setState({ apiResponse: data, nameError: data.message })
            if (this.state.nameError != null && this.state.nameError != undefined) {
              swal("Oops!!", this.state.nameError, "error")
            }
            else {
              swal({
                text: "You have successfully updated configuration!!",
                icon: "success",
              }).then((okay) => {
                if (okay) {
                  this.setState({ addModalShowPopUp: false })
                  window.location.href = process.env.REACT_APP_REDIRECT_URL + "TalentConfigurations";
                }
              });
            }
            console.log(data);
            return data;
          })
      }
    })
  };


  formatDate(string) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }


  descUpdate = (event) => {
    let flag = event.target.value ? true : false;

    this.setState(
      {
        description: event.target.value,
        submitDisabled: false
      }, () => console.log("Description Update", this.state.description)
    );
  };

  render() {
    const {
      talentConfig,
      testerList,
      testerNotInConfig,
      addModalShow,
      addModalShowMatch,
      addModalShowPopUp,
      data,
      data1,
      submitDisabled,
      disableSavebtn,
      description
    } = this.state;
    console.log("Updated  Data1", data1);
    console.log("DATA", data.history)
    console.log("Updated API Data ", data);
    console.log("Description: ", description)
    console.log("No of Tester", testerList.length)
    const prevState = data1
    let addModalClose = () => {
      this.setState({ addModalShow: false, addModalShowMatch: false, data1: [...this.state.prevData], disableSavebtn: true })
    };

    let addModalClosePopUp = () => this.setState({ addModalShowPopUp: false })
    let configurationName = () =>
      this.setState({ configName: this.props.match.params });
    const talentConfigurationName = this.props.match.params
      .talentConfigurationName;

    return (
      <div className="container">
        <div className="py-4">
          <Tabs>
            <TabList>
              <div className="name">
                <h9 className="headerInline addNewStyling textSizeHeader">{talentConfigurationName.toUpperCase()}</h9>
                <div className="button headerInline headerMargin textSizeHeader">
                  <button
                    className="link-align disable-styling button-styling"
                    onClick={(event) =>
                      (window.location.href =
                        process.env.REACT_APP_REDIRECT_URL + "TalentConfigurations")
                    }
                  >
                    Discard
                  </button>
                  <button
                    className="link-align enable-styling button-styling"
                    onClick={this.onSubmitRequest}
                    disabled={this.state.submitDisabled}
                  >
                    Save
                  </button>
                </div>
              </div>

              <Tab tabFor="horizental-tab-one" className="addNewStyling textSizeHeader">Configuration</Tab>

              <Tab tabFor="horizental-tab-two" className="addNewStyling textSizeHeader">Testers</Tab>
            </TabList>
            <br></br>
            <TabPanel tabId="horizental-tab-one">
              <div className="custom-control custom-switch right">
                <div>
                  <input
                    type="checkbox"
                    checked={this.state.status}
                    value={this.state.status}
                    onChange={this.handleChange}
                    className="custom-control-input headerInline"
                    id="customSwitch1"
                  />
                  <label className="custom-control-label addNewStyling textSizeHeader headerInline" for="customSwitch1" >
                    {this.state.status ? 'Enabled' : 'Disabled'}
                  </label>
                  <span className="textSizeHeader">&nbsp;&nbsp;|</span>&nbsp;&nbsp;
                <button
                    className="link-align addNewStyling button-styling textSizeHeader headerMargin"
                    onClick={() => this.setState({ addModalShowMatch: true })}
                  > Mapping Logic
                </button>
                </div>
                <MatchingLogicPopUp
                  data1={data1}
                  show={this.state.addModalShowMatch}
                  onHide={addModalClose}
                  mappingAdded={this.onMappingChange}
                  onSubmit={this.onSubmit}
                  onInputChange={this.onInputChange}
                  disableSavebtn={this.state.disableSavebtn}
                />
                <p>{this.state.ChangeConfigurationMapping}</p>
                {/* <p>{this.state.ChangeConfigurationMapping}</p> */}
              </div>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text textSize addNewStyling">Description:</span>
                </div>
                <textarea class="form-control textSize" aria-label="With textarea"
                  onChange={this.descUpdate}
                  value={description}
                ></textarea>
              </div>


              <div className="viewConfigs">
                {talentConfig.configuration &&
                  config.map((item, index) => {
                    return (
                      <>
                        <h8 className="addNewStyling textSizeSemiHeader">{item}</h8>
                        <div className="main-wrapper" key={index}>
                          {talentConfig.configuration[item].map(
                            (childItem, childIndex) => {
                              return (
                                <div className="sections textSize upperCaseFont wrapContent" key={childIndex}>
                                  <input
                                    className="checkboxSpacing"
                                    type="checkbox"
                                    onChange={(event) => {
                                      this.handleConfig(
                                        event.target.checked,
                                        item,
                                        childItem
                                      );
                                    }}
                                    id={childItem}
                                    value={childItem}
                                    checked={this.verifyCheckbox(
                                      item,
                                      childItem
                                    )}
                                  ></input>
                                  <label className="checkboxSpacing"
                                  // for={childItem} //commenting this to avoid checkbox selection on text click
                                  >{childItem}</label>
                                </div>
                              );
                            }
                          )}
                        </div>

                      </>
                    );
                  })}
              </div>
              <hr />
              <div className="container">
                <div className="row">
                  {/* {Coloumn 1} */}
                  <div className="col textSizeHeader addNewStyling" >
                    <h9>CreatedBy: {data.history && Object.entries(data.history)[0][1]}</h9>
                    <br></br>
                    <h9>CreatedOn: {data.history && Object.entries(data.history)[1][1]}</h9>
                  </div>
                  {/* {Coloumn 2} */}
                  <div className="col textSizeHeader addNewStyling custom-control">
                    <h9>ModifiedBy: {data.history && Object.entries(data.history)[2][1]}</h9>
                    <br></br>
                    <h9>ModifiedOn: {data.history && Object.entries(data.history)[3][1]}</h9>
                    {/* <h9>ModifiedOn: {data.history && this.formatDate(Object.entries(data.history)[3][1])}</h9> */}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="horizental-tab-two">
              {/* <ButtonToolbar className="float-right"> */}
              <button className="link-align addNewStyling button-styling headerMargin textSizeSemiHeader"
                onClick={() => this.setState({ addModalShow: true })} >
                Add Tester
              </button>

              <AddTesterPopUp
                show={this.state.addModalShow}
                onHide={addModalClose}
                data={talentConfigurationName}
              />

              <table class="table w3-hoverable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Alias</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {testerList && testerList.length ? (
                    testerList.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.testerName}</td>
                        <td>{item.testerName}</td>
                        <td>{item.currentStatus}</td>
                        <td>{item.reason !== null ? item.reason : "-"}</td>
                        <td>
                          <button className="link-align remove-styling button-styling" onClick={() => this.sumbitRequest(item.testerName, talentConfigurationName)
                          }>Remove</button>
                        </td>
                      </tr>
                    ))
                  ) : (
                      <tr>
                        <th scope="row">{1}</th>
                        <td>
                          No Testers assigned with talent Configuration named{" "}
                          <b> {talentConfigurationName}</b>{" "}
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>

            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default withRouter(ViewConfigs);
