import React from "react";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import UserName from '../Auth/UserName';
import * as FaIcons  from "react-icons/fa";
import * as FcIcons  from "react-icons/fc";
import UserAccess  from '../Auth/UserAccess';
import UserId from '../Auth/UserId';




export default props => {
  const accessLevel = UserAccess();
  const currentUser = UserId();
 
  if(accessLevel==="unknown"){
    return (
  
      <Menu className="bm-menu "{...props}> 
              <li className="nav-item "></li>              
      </Menu>
     
    );
  }
  else if(accessLevel==="Admin") 
 {
  return (
    // Pass on our props

    <Menu className="bm-menu "{...props}> 
            <li className="nav-item "></li>

            <li className="nav-item menuSpacing addNewStyling textSizeHeader"> <FaIcons.FaUserCircle/> <UserName/></li>
            <li className="nav-item addNewStyling textSizeHeader greyOut"> <UserAccess/></li>

            <li className="nav-item menuSeprartor"><br></br></li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item" exact to="/TesterProfile">
              <FaIcons.FaUsers/>    Tester Profiles
              </NavLink>
            </li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item nav-item" exact to="/TalentConfigurations"> 
               <FaIcons.FaBookOpen/>   Talent Configurations
              </NavLink>
            </li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item" to="/configs/add">
                <FaIcons.FaFileSignature/>    Create Talent Configuration
              </NavLink>
            </li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item" to="/disabled-configurations">
                <FaIcons.FaMicrosoft/>    Disabled Configurations
              </NavLink>
            </li>

            <li className="nav-item menuSeprartor textSizeHeader">
              <NavLink className="nav-link bm-item" exact to="/ValidBadges">
               <FaIcons.FaEdit/>    Manage Attributes
              </NavLink>
            </li>

            <li className="nav-item menuBottomSpacing textSizeSemiHeader"> 
               <a className="bm-item"  href="https://nasabeta.aka.corp.amazon.com/" target='_blank'> <FaIcons.FaAngleDoubleRight/>    Go to NASA</a>
            </li>
          
          <li className="nav-item   textSizeSemiHeader"> 
             <a className="bm-item" href="https://tiny.amazon.com/c0uxzrr3" target='_blank'>  <FaIcons.FaBug/>  Report an issue</a>
          </li>

            <li className="nav-item menuSpacing textSizeSemiHeader"> 
               <a className="bm-item"  href="https://wiki.labcollab.net/confluence/pages/viewpage.action?pageId=1020956316" target='_blank'> <FaIcons.FaInfoCircle/>    About Us </a>
            </li>
           
           
        
            
    </Menu>
   
  );
}
     else if(accessLevel==="Tester"){
    return (
      // Pass on our props
  
      <Menu className="bm-menu "{...props}> 
              <li className="nav-item "></li>
  
              <li className="nav-item menuSpacing addNewStyling textSizeHeader"> <FaIcons.FaUserCircle/> <UserName/></li>
              <li className="nav-item addNewStyling textSizeHeader greyOut"> <UserAccess/></li>
              <li className="nav-item addNewStyling   textSizeHeader"> </li>
              <li className="nav-item addNewStyling  menuSeprartor textSizeHeader"> </li>
              <li className="nav-item menuSeprartor textSizeHeader">
                <NavLink className="nav-link bm-item"  to={`/TesterView/${currentUser}`} >
                <FaIcons.FaAddressCard/>    My Profile </NavLink></li>
          
              <li className="nav-item menuBottomSpacing textSizeSemiHeader"> 
                <a className="bm-item"  href="https://nasabeta.aka.corp.amazon.com/" target='_blank'> <FaIcons.FaAngleDoubleRight/>    Go to NASA</a>
              </li>
          
              <li className="nav-item   textSizeSemiHeader"> 
                <a className="bm-item" href="https://tiny.amazon.com/c0uxzrr3" target='_blank'>  <FaIcons.FaBug/>  Report an issue</a>
              </li>

              <li className="nav-item menuSpacing textSizeSemiHeader"> 
                <a className="bm-item"  href="https://wiki.labcollab.net/confluence/pages/viewpage.action?pageId=1020956316" target='_blank'> <FaIcons.FaInfoCircle/>    About Us </a>
              </li>
              
      </Menu>
     
    );
  } 
  };
 