import React, { Component } from "react";
import { Modal, Button, ButtonToolbar } from "react-bootstrap";
import axios from "axios";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import Select from "react-select";
import swal from "sweetalert";

export class AddTesterPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testerNotInConfig: [],
      defaultSelect: "",
      priority: "",
      configError: "",
      priorityError: ""
    };
  }

  componentDidMount() {
    console.log("POP UP CONFIG NAME " + this.props.data);
    axios
      .get(
        process.env.REACT_APP_AQUILINE_BACKEND + "getTestersNotInTalentConfiguration/" + this.props.data
      )
      .then((res) => {
        const options = this.getFormatedData(res.data);
        console.log(options);
        this.setState({
          testerNotInConfig: options,
        });
        console.log(res.data);
      });
  }

  getFormatedData = (options) => {
    let result = [];

    for (let i = 0; i < options.length; i++) {
      result.push({
        label: options[i],
        value: options[i],
      });
    }
    return result;
  };

  handleChange = (selectedOption) => {
    this.setState({ defaultSelect: selectedOption }, () => {
      console.log(this.state);
    });
    this.getEntitledConfigByTester(selectedOption.value);
  };

  handlePriorityChange = (event) => {
    this.setState({ priority: event.target.value }, () => {
      console.log(this.state);
    });
  };

  handleValidation() {
    let priority = this.state.priority;
    let defaultSelect = this.state.defaultSelect;
    let errors = {};
    let formIsValid = true;

    if (!priority) {
      formIsValid = false
      errors["priority"] = "Cannot be empty";
    }

    if (!defaultSelect) {
      formIsValid = false
      errors["defaultSelect"] = "Cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }


  getEntitledConfigByTester(testerName) {
    // const testerName = this.state.selectedOption.value;
    console.log(this.props);
    axios
      .get(process.env.REACT_APP_AQUILINE_BACKEND + "getTP/" + testerName)
      .then((res) => {
        this.setState({
          data: res.data,
        });
        console.log("Checking" + res.data);
      });
  }



  validate = () => {
    let configError = "";
    let priorityError = "";
    if (this.state.defaultSelect == "") {
      configError = "Please select Config"
    }

    if (configError) {
      this.setState({ configError })
      return false
    }
    else {
      // this.getEntitledConfigByTester();
      // const { entitledConfigurations } = this.state.data && this.state.data.entitledConfigurations
      let pList = [];
      if (this.state.data.entitledConfigurations !== null) {

        pList = Object.values(this.state.data.entitledConfigurations) && Object.values(this.state.data.entitledConfigurations)
      }

      if (this.state.priority == "") {
        priorityError = "Priority can not be blank"
      } else if (pList !== undefined && pList.includes(parseInt(this.state.priority, 10))) {
        priorityError = "Oops!!" + pList.join() + " values  are already present.Please try some other values ..."
      }
      if (configError || priorityError) {
        this.setState({ configError, priorityError });
        return false;
      }
      return true
    }
  }

  handleSubmit = () => {
    const isValid = this.validate();
    if (isValid) {
      let requestBody = {
        userId: this.state.defaultSelect ? this.state.defaultSelect.value : "",
        entitledConfigurations: {
          [this.props.data]: this.state.priority,
        },
      };
      console.log(requestBody);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      };
      console.log(requestOptions);

      fetch(
        process.env.REACT_APP_AQUILINE_BACKEND + "addTesterToTC",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          return data;
        })
        .then((data) => this.setState({ apiResponse: data }));
      swal({
        text: "You have successfully added Tester",
        type: "success",
      }).then((okay) => {
        if (okay) {
          window.location.href = process.env.REACT_APP_REDIRECT_URL + "TalentConfigurations";
        }
      });
    }
  };

  onHide = () => {
    this.setState({ defaultSelect: "", priorityError: "", configError: "", priority: "" }, () => {
      this.props.onHide()
    });
  };

  render() {
    const { testerNotInConfig, defaultSelect, data } = this.state;
    console.log("ConfigName " + this.props.data);
    // let pList = []
    // pList = Object.values(this.state.data.entitledConfigurations)
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={this.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" class="textSizeHeader addNewStyling">
            Add Tester to Talent Configuration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="textSizeSemiHeader addNewStyling ">

            <br></br>
            Select tester to be added:

            <Select
              class="browser-default custom-select"
              value={defaultSelect}
              onChange={this.handleChange}
              options={testerNotInConfig}
            ></Select>
            <div style={{ color: "red" }}>{this.state.configError}</div>
            <br></br>
            {/* <InputGroup> */}
            {/* <InputGroupAddon className="textSize addNewStyling">Prority</InputGroupAddon> */}
              Select priority for the configuration:
              <br></br>
            <Input
              placeholder="1-99"
              className="textSizeSemiHeader addNewStyling"
              min={1}
              max={99}
              type="number"
              step="1"
              onChange={this.handlePriorityChange}
            />
            <div style={{ color: "red" }}>{this.state.priorityError}</div>
            {/* </InputGroup> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="link-align enable-styling button-styling textSizeHeader" variant="primary" onClick={this.handleSubmit}>
            Save
          </Button>
          <Button variant="danger" className="link-align disable-styling button-styling  textSizeHeader" onClick={this.onHide}>
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}