import React, { useEffect, useState } from 'react';
import LoadingScreen from './LoadingScreen';
import UserName from '../Auth/UserName';
import UserAccess from '../Auth/UserAccess';

const Homepage = () => {

    var userName = UserName();
    var userAccess = UserAccess();

    if(userName != 'notLoggedInDa'){
        return (
            <div id="page-wrap">
                <br/> <br/> <br/> <br/>
            <h1> Hi {userName}, Welcome to Aquiline! <span role="img" aria-label="star"></span> </h1>
            <h2> You have {userAccess} access! </h2> <br/>
            <h4> Check out list of available modules in the sidebar menu.<span role="img" aria-label="star"></span></h4> <br/>
            <h4>Aquiline UI implementation is still in-progress <span role="img" aria-label="star">👨‍💻</span>. Limited features are available for the demo 🎥.  </h4>
            </div>
            )
    }
    else{
        return (
            <div>
                <LoadingScreen/>
                {<UserName/>}
            </div>
        )
    };
}
    export default Homepage;