import React, { Component } from "react";
import { Modal, Button, ButtonToolbar } from "react-bootstrap";
import axios from "axios";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import Select from "react-select";
import swal from "sweetalert";
import { prototype } from "react-modal";

export class AddConfigPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configNotInTeserProfile: [],
      defaultSelect: "",
      priority: "",
      configError: "",
      priorityError: "",
      duplicateError: ""
    };
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    console.log("POP UP CONFIG NAME " + this.props.data);
    console.log("TEST", this.props.priority)
    axios
      .get(
        process.env.REACT_APP_AQUILINE_BACKEND + "getTalentConfigurationsNotInTesterProfile/" + this.props.data
      )
      .then((res) => {
        const options = this.getFormatedData(res.data);
        console.log(options);
        this.setState({
          configNotInTeserProfile: options
        });
        console.log(res.data);
      });
  }

  getFormatedData = (options) => {
    let result = [{ label: "", value: "" }];

    for (let i = 0; i < options.length; i++) {
      result.push({
        label: options[i],
        value: options[i],
      });
    }
    return result;
  };

  handleChange = (selectedOption) => {
    this.setState({ defaultSelect: selectedOption }, () => {
      console.log("Selected Config", this.state);
    });
  };

  handlePriorityChange = (event) => {
    this.setState({ priority: event.target.value }, () => {
      console.log("Given Priority ", this.state);
    });
  };

  validate = () => {
    let configError = "";
    let priorityError = "";
    let duplicateError = ""
    if (this.props.list === null) {
      let configError = "Please select Config";
    } else {

      // if (this.props.list === null) { return true }
      console.log("List" + this.props)
      let pList = [];
      if (this.props.list !== null) {
        pList = Object.values(this.props.list) && Object.values(this.props.list)
      }

      if (this.state.defaultSelect == "" || this.props.list.length == 0) {
        configError = "Please select Config"
      }

      if (this.state.priority == "") {
        priorityError = "Priority can not be blank"
      } else if (pList.includes(parseInt(this.state.priority, 10))) {
        priorityError = "Oops!!" + pList.join() + " values  are already present.Please try some other values ..."
      }
    }


    if (configError || priorityError || duplicateError) {
      this.setState({ configError, priorityError });
      return false;
    }
    return true
  }

  handleSubmit = () => {
    const isValid = this.validate();
    if (isValid) {

      let requestBody = {
        //   userId: this.state.defaultSelect ? this.state.defaultSelect.value : "",
        userId: this.props.data,
        entitledConfigurations: {
          [this.state.defaultSelect.value]: this.state.priority,
        },
      };

      console.log(requestBody);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      };
      console.log(requestOptions);

      fetch(
        process.env.REACT_APP_AQUILINE_BACKEND + "addTesterToTC",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          return data;
        })
        .then((data) => this.setState({ apiResponse: data }));
      swal({
        text: "You have successfully added Configuration to Tester Profile",
        type: "success",
      }).then((okay) => {
        if (okay) {
          window.location.href = process.env.REACT_APP_REDIRECT_URL + "TesterProfile";
        }
      });
    }
  };

  setValue = value => {
    this.props.setState(prevState => ({
      defaultSelect: {
        ...prevState.defaultSelect.value,
        value
      }
    }));
  };
  onHide = () => {
    this.setState({ defaultSelect: "", priorityError: "", configError: "", duplicateError: "", priority: "" }, () => {
      this.props.onHide()
    });
  };


  render() {
    const { configNotInTeserProfile, defaultSelect } = this.state;
    console.log("TesterName " + this.props.data);
    console.log("List" + this.props)

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={this.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="textSizeHeader addNewStyling">
            Add Configuration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="textSizeSemiHeader addNewStyling">
            <br></br>
            Add Configuration To Tester Profile
            <Select
              ref="defaultSelect"
              class="browser-default custom-select"
              id="Config"
              name="Config"
              value={defaultSelect}
              // defaultValue={defaultValue}
              onChange={this.handleChange}
              options={configNotInTeserProfile}
            ></Select>
            <div style={{ color: "red" }}>{this.state.configError}</div>
            <br></br>''
            Select priority for the configuration:
              <Input
              ref="priority"
              id="Priority"
              name="Priority"
              placeholder="1-99"
              className="textSizeSemiHeader addNewStyling"
              min={1}
              max={99}
              type="number"
              step="1"
              onChange={this.handlePriorityChange}
            />
            <div style={{ color: "red" }}>{this.state.priorityError}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary" className="link-align enable-styling  button-styling textSizeHeader" onClick={this.handleSubmit}>
            Save
          </Button>
          <Button variant="danger" className="link-align disable-styling button-styling  textSizeHeader" onClick={this.onHide}>
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
